import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { Modal } from "clutch/src/Modal/Modal.jsx";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import { readState } from "@/__main__/app-state.mjs";
import ArenaComingSoon from "@/game-lol/components/ArenaModeComingSoon.jsx";
import {
  AugmentTable,
  itemList,
  itemLists,
  ItemsCard,
  Layout,
  StatsModalContainer,
  StyledFilterBar,
  teammatesList,
} from "@/game-lol/components/ChampionArena.style.jsx";
import ChampionImg from "@/game-lol/components/ChampionImg.jsx";
import ItemImg from "@/game-lol/components/ItemImg.jsx";
import lolRefs from "@/game-lol/refs.mjs";
import {
  championItems,
  dataDuosList,
  formatBuildArena,
  getKeyForArenaChampionData,
  placementColor,
  top2Color,
  top4Color,
  winRateColor,
} from "@/game-lol/utils/arena-queue-utils.mjs";
import useArenaAugments, {
  AUGMENT_TIER_FILTERS,
} from "@/game-lol/utils/useArenaAugments.jsx";
import useChampionsArenaDuos from "@/game-lol/utils/useChampionsArenaDuos.jsx";
import useChampionsFilter from "@/game-lol/utils/useChampionsFilter.jsx";
import useChampionsTable from "@/game-lol/utils/useChampionsTable.jsx";
import { getStaticData } from "@/game-lol/utils/util.mjs";
import ChampionStatsBar from "@/shared/CharacterStatsBar.jsx";
import DataTable from "@/shared/DataTable.jsx";
import getTierIcon from "@/shared/get-tier-icon.mjs";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import TableLayout from "@/shared/TableLayout.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useIsLoaded } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const STATS_ENABLED = true;
// const MAX_AUGMENTS = 5;

const STATS_KEYS = [
  {
    key: "avg_placement",
    title: ["common:avgPlace", "Avg. Place"],
    format: {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    colorFn: (v) => placementColor(v),
  },
  {
    key: "top_1_percent",
    title: ["lol:arena.firstPlace", "1st Place"],
    format: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: "percent",
    },
    colorFn: (v) => winRateColor(v * 100),
  },
  {
    key: "top_2_percent",
    title: ["lol:arena.top2", "Top 2"],
    format: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: "percent",
    },
    colorFn: (v) => top2Color(v * 100),
  },
  {
    key: "top_4_percent",
    title: ["lol:arena.top4", "Top 4"],
    format: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: "percent",
    },
    colorFn: (v) => top4Color(v * 100),
  },
  {
    key: "pick_rate",
    title: ["lol:pickRate", "Pick Rate"],
    format: {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
      style: "percent",
    },
  },
  /* {
    key: "ban_rate",
    title: ["lol:banRate", "Ban Rate"],
    format: {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
      style: "percent",
    },
  }, */
  {
    key: "num_duos",
    title: ["common:appearances", "Appearances"],
    format: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
];

const Items = ({ items = {}, hasTeammates = true }) => {
  const { t } = useTranslation();

  const list = championItems(items);
  const {
    items_starting,
    items_completed,
    items_prismatic,
    items_boots,
    stats: { items: itemsStats = {} },
  } = formatBuildArena({ build: list });

  return (
    <ItemsCard className={!hasTeammates && "no-teammates"}>
      <Card>
        <div className={itemLists()}>
          {items_prismatic.length ? (
            <div>
              <span className="type-subtitle--bold build-title">
                {t("lol:championData.prismatics", "Prismatics")}
              </span>
              <div className={itemList()}>
                {items_prismatic.map((id) => (
                  <ItemRender
                    key={id}
                    isPrismatic
                    id={id}
                    stats={itemsStats[id]}
                  />
                ))}
              </div>
            </div>
          ) : null}
          <div>
            <span className="type-subtitle--bold build-title">
              {t("lol:championData.commonItems", "Common Items")}
            </span>
            <div className={itemList()}>
              {items_completed.map((id) => (
                <ItemRender key={id} id={id} stats={itemsStats[id]} />
              ))}
            </div>
          </div>
          <div>
            <span className="type-subtitle--bold build-title">
              {t("lol:championData.starting", "Starting")}
            </span>
            <div className={itemList()}>
              {items_starting.map((id) => (
                <ItemRender key={id} hideTier id={id} stats={itemsStats[id]} />
              ))}
            </div>
          </div>
          <div>
            <span className="type-subtitle--bold build-title">
              {t("lol:itemsets.bootsOptions", "Boots Options")}
            </span>
            <div className={itemList()}>
              {items_boots.map((id) => (
                <ItemRender key={id} hideTier id={id} stats={itemsStats[id]} />
              ))}
            </div>
          </div>
        </div>
      </Card>
    </ItemsCard>
  );
};

const ItemRender = ({ id, isPrismatic, hideTier, stats = {} }) => {
  const { t } = useTranslation();
  const itemSize = isPrismatic ? 2.75 * 1.25 : 2.75;
  const TierIcon = getTierIcon(stats.tier);

  return (
    <div key={id} data-id={id} className="item">
      <div className="image-container">
        <ItemImg size={itemSize} borderRadius={5} itemId={id} />
        {!hideTier && <TierIcon />}
      </div>
      <div className="item-stats">
        <span className="type-caption">
          {t("lol:percentPickRate", "{{pickrate}}% Pick Rate", {
            pickrate: (stats.pick_rate * 100).toLocaleString(getLocale(), {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }),
          })}
        </span>
        {/* <span className="type-caption">
          {t("lol:percentTopFourRate", "{{topFourRate}}% Top 4", {
            topFourRate: (stats.top_4_percent * 100).toLocaleString(
              getLocale(),
              {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            ),
          })}
        </span> */}
      </div>
    </div>
  );
};

const Teammates = ({ teammates = {}, championId }) => {
  const { t } = useTranslation();
  const modalRef = useRef();
  const champions = getStaticData("champions");
  const championInfo = champions?.[champions?.keys?.[championId]];
  const list = dataDuosList(teammates, champions, championId).sort(
    (a, b) => a.avg_placement - b.avg_placement,
  );

  return (
    <>
      <ListItemsTruncated
        title={
          championInfo
            ? t(
                "lol:arena.bestTeammatesWithChampionName",
                "Best teammates with {{championName}}",
                { championName: championInfo?.name },
              )
            : t("lol:arena.bestTeammates", "Best Teammates")
        }
        list={list}
        initialCount={7}
        className={teammatesList()}
        itemLinkFormat={(item) => {
          const staticKey = champions.keys[item.id2];
          return `/${lolRefs.lolChampionPrefix}/${staticKey}/arena`;
        }}
        // showMoreOverrideFn={() => {
        //   modalRef.current?.showModal();
        // }}
        itemLeftContent={(item) => {
          const TierIcon = getTierIcon(item.tier);
          const staticKey = champions.keys[item.id2];
          const staticChampion = champions[staticKey];

          return (
            <>
              <div className="champion-tier">
                <ChampionImg disabled championId={item.id2} size={40} />
                {TierIcon && <TierIcon />}
              </div>
              <div>
                <p className="type-callout--bold">{staticChampion.name}</p>
                <div className="champion-stats">
                  {STATS_KEYS.filter(
                    (s) => s.key !== "pick_rate" && s.key !== "ban_rate",
                  ).map((stat) => (
                    <span key={stat.key} className="stat">
                      <span className="type-caption">{t(...stat.title)}:</span>{" "}
                      <span
                        className="val type-caption--bold"
                        style={{
                          color: stat.colorFn && stat.colorFn(item[stat.key]),
                        }}
                      >
                        {(item[stat.key] ?? 0).toLocaleString(
                          getLocale(),
                          stat.format,
                        )}
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            </>
          );
        }}
      />
      <Modal ref={modalRef}>
        <TeammatesAll championId={championId} />
      </Modal>
    </>
  );
};

const TeammatesAll = ({ championId }) => {
  const { FilterBar, ...filterParams } = useChampionsFilter({
    tab: "arena",
  });

  const isLoaded = useIsLoaded();

  const { data, cols, colRenderer, options } = useChampionsArenaDuos({
    filterParams,
    championId,
  });
  const { TableView } = useChampionsTable({
    tableData: data,
    cols,
    colRenderer,
    isLoaded,
    options,
    topOffset: 0,
  });

  return (
    <StatsModalContainer>
      <Card padding={0}>
        <TableLayout filters={FilterBar} table={TableView} />
      </Card>
    </StatsModalContainer>
  );
};

const Augments = ({ championId }) => {
  const { t } = useTranslation();

  const [searchText, setSearchText] = useState("");
  const [selectedTier, setSelectedTier] = useState("ALL");

  const { cols, rows } = useArenaAugments({
    searchText,
    selectedTier,
    championId,
  });

  return (
    <>
      <StyledFilterBar>
        <TextInput
          defaultValue={searchText}
          placeholder={t("lol:searchAugments", "Search Augments")}
          onValueChange={(s) => setSearchText(s)}
        />
        <ButtonGroup>
          {AUGMENT_TIER_FILTERS.map((tier) => (
            <Button
              key={tier.key}
              active={selectedTier === tier.key}
              onClick={() => setSelectedTier(tier.key)}
            >
              {t(...tier.name)}
            </Button>
          ))}
        </ButtonGroup>
      </StyledFilterBar>
      <DataTable
        tallRows
        cols={cols}
        rows={rows}
        indexCol
        classNameOuter={AugmentTable()}
        sortCol={1} // Tier
        sortColTiebreak={2} // Pick Rate
        sortDir="DESC"
        searchText={searchText}
        searchCol={0}
      />
    </>
  );
};
const ChampionArena = ({ championId }) => {
  const { lol } = useSnapshot(readState);
  const patch = lol.arenaStatsPatch?.patch;
  const key = getKeyForArenaChampionData(patch, championId);
  const stats = lol.championStats?.[key];

  if (!STATS_ENABLED) return <ArenaComingSoon />;

  if (!championId || !stats) {
    return (
      <Layout>
        <ChampionStatsBar stats={[]} loading />
        <Card loading style={{ height: 530 }} />
        <Card loading style={{ height: 530 }} />
      </Layout>
    );
  }

  if (stats instanceof Error) return <ArenaComingSoon />;

  const hasTeammates = !!Object.keys(stats.teammates || {}).length;

  const statsRender = STATS_KEYS.map((stat) => {
    return {
      key: stat.key,
      title: stat.title,
      format: stat.format,
      color: stat.colorFn && stat.colorFn(stats[stat.key]),
      value: stats[stat.key],
    };
  });

  if (stats.tier) {
    const Icon = getTierIcon(stats.tier);
    statsRender.unshift({
      key: "tier",
      title: ["common:tier", "Tier"],
      value: stats.tier,
      color: "",
      format: {},
      icon: <Icon />,
    });
  }

  return (
    <Layout>
      <ChampionStatsBar stats={statsRender} />
      <Items items={stats.items} hasTeammates={hasTeammates} />
      {hasTeammates && (
        <Teammates championId={championId} teammates={stats.teammates} />
      )}
      <Augments championId={championId} />
    </Layout>
  );
};

export default React.memo(ChampionArena);
