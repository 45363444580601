import React from "react";

import ChampionBuilds from "@/game-lol/components/ChampionBuilds.jsx";
import ChampionInsights from "@/game-lol/components/ChampionInsights.jsx";
import ChampionOverviewTrends from "@/game-lol/components/ChampionOverviewTrends.jsx";
import { QUEUE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import { getQueueDetails } from "@/game-lol/utils/symbol-queue.mjs";
import { getWinRateColor } from "@/game-lol/utils/util.mjs";
import ChampionStatsBar from "@/shared/CharacterStatsBar.jsx";
import getTierIcon from "@/shared/get-tier-icon.mjs";

const ChampionAram = ({
  championId,
  champion,
  championStats,
  filters,
  patch,
}) => {
  if (!champion) {
    return (
      <>
        <ChampionStatsBar stats={[]} loading />
        <ChampionBuilds />
      </>
    );
  }

  const queue = getQueueDetails(QUEUE_SYMBOLS.aram).gql;

  const renderedStats = championStats
    ? [
        {
          key: "winrate",
          title: ["lol:winRate", "Win Rate"],
          value: championStats.wins / championStats.games,
          color: getWinRateColor(
            (championStats.wins / championStats.games) * 100,
          ),
          format: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            style: "percent",
          },
        },
        {
          key: "pickrate",
          title: ["lol:pickRate", "Pick Rate"],
          value: championStats.pickRate,
          color: "",
          format: {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
            style: "percent",
          },
        },
        {
          key: "matches",
          title: ["common:matches", "Matches"],
          value: championStats.games,
          color: "",
          format: {},
        },
      ]
    : [];

  if (championStats?.tierListTier?.tierRank) {
    const Icon = getTierIcon(championStats.tierListTier.tierRank);
    renderedStats.unshift({
      key: "tier",
      title: ["common:tier", "Tier"],
      value: championStats.tierListTier.tierRank,
      color: "",
      format: {},
      icon: <Icon />,
    });
  }

  return (
    <>
      <ChampionStatsBar stats={renderedStats} loading={!championStats} />
      <ChampionBuilds
        champion={champion}
        championId={champion.id}
        championRole={filters.role}
        queue={queue}
        region={filters.region}
      />
      <ChampionInsights championId={champion?.id} champion={champion} />
      <ChampionOverviewTrends
        championId={championId}
        queue={filters.queue}
        champion={champion}
        filters={filters}
        patch={patch}
      />
    </>
  );
};

export default React.memo(ChampionAram);
